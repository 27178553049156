import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import GameData from '../../model/entities/GameData.js'

//STORE STARTS HERE

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiUrl: 'https://wyd-backend.fhtl.org/api',
    // apiUrl: process.env.NODE_ENV==="development"? "http://localhost:5000/api" : "https://wyd-backend-prd.byu-dept-fhtl-prd.amazon.byu.edu/api",
    fsToken: '',
    playerCodes: {
      playerOne: 'playerOne',
      playerTwo: 'playerTwo'
    },
    mysteryAncestor: null,
    person: {
      pid: '',
      playerCode: ''
    },
    game: new GameData()
  },
  mutations: {
    logout: state => {
      state.fsToken = ''
      state.person.pid = ''
      state.game = new GameData()
      localStorage.removeItem('fsToken')
      localStorage.removeItem('gameData')
    },
    reset(state) {
      state.person.playerCode = ''
      state.game = new GameData()
      state.mysteryAncestor = null
      localStorage.removeItem('gameData')
    },
    setGameData(state, data) {
      state.game = data
      localStorage.setItem('gameData', JSON.stringify(data))
    },
    setMysteryAncestor(state, ancestor) {
      state.mysteryAncestor = ancestor
      state.game.mysteryAncestor = ancestor
      localStorage.setItem('gameData', JSON.stringify(state.game))
    }
  },
  actions: {
    determineLoginStatus(context) {
      function parseJWT(token) {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
        )

        return JSON.parse(jsonPayload)
      }

      // Get the url and token
      const url = window.location.href
      const tokens = url.split('?fstoken=')

      // Get the document cookies
      const cookieString = document.cookie
      console.log(cookieString)

      let fsToken: any = null
      // Check if the FS_SSO_JWT_TOKEN cookie is set
      if (cookieString.includes('FS_SSO_JWT_TOKEN')) {
        fsToken = cookieString.split('FS_SSO_JWT_TOKEN=')[1].split(';')[0]
      }
      // If there are 2 tokens, it means the fstoken was present, and it's the second portion
      else if (tokens.length === 2) {
        fsToken = tokens[1]
      }

      if (fsToken !== null) {
        const fsInfo = parseJWT(fsToken) // Convert the JWT token back into a Javascript object

        // Store fsInfo
        context.state.person.pid = fsInfo.fs_user.pid
        context.state.fsToken = fsInfo.fs_access_token
        window.history.pushState("", "Title", "/");
      }
    },

    testLogin: () => {
      alert('button works')
    },

    login: () => {
      window.location = ('https://auth.fhtl.org' +
        '?redirect=' +
        window.location.origin +
        '/&site=key') as unknown as Location;
    },    

    logout({ commit }) {
      return new Promise<void>(resolve => {
        commit('logout')
        const authUrl = 'https://auth.fhtl.org'
        const redirectUri = window.location.origin
        let ssoToken = document.cookie.replace(
          /(?:(?:^|.*;\s*)FS_SSO_JWT_TOKEN\s*\=\s*([^;]*).*$)|^.*$/,
          '$1'
        )
        window.location.href = `${authUrl}/logout?redirect=${redirectUri}&fstoken=${ssoToken}`
        resolve()
      })
    }
  },
  getters: {
    isLoggedIn: state => !!state.fsToken
  }
})
